import $ from 'jquery';

import '../styles/index.scss'; // webpackでコンパイルするため必須. 実際には.jsには展開されない.


$(document).ready(() => {

  //https://junpei-sugiyama.com/page-top-stop/
  $("#js-btn-pagetop").hide();
  $(window).on("scroll", function () {
    const scrollHeight = $(document).height();
    const scrollPosition = $(window).height() + $(window).scrollTop();
    const footHeight = $("footer").innerHeight();
    if (scrollHeight - scrollPosition <= footHeight) {
      $("#js-btn-pagetop").css({ position: "absolute", bottom: footHeight + 0 });
    } else {
      $("#js-btn-pagetop").css({ position: "fixed", bottom: "0" });
    }
  });

  
  // ハンバーガーメニュー開閉
  $('.js-spBtn').on('click', function() {
    $('.js-spBtn').toggleClass('is-open');
    $('.js-spNav').toggleClass('is-open');
    $('body').delay(1000).toggleClass('is-navOpen');
  });

  $(document).on('click', function(event) {
    if(!$(event.target).closest('.js-spBtn, .js-spNav').length) {
      $('.js-spBtn, .js-spNav').removeClass('is-open');
      $('body').removeClass('is-navOpen');
    }
  });

  // スムーズスクロール
  $('a[href^="#"]').on('click', function() {
    const adjust = 100;
    const speed = 400;
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top - adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    // return false;
  });

  // ==============================
  // ViewPort Height
  // ==============================
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  let vw = window.innerWidth;
  window.addEventListener('resize', () => {
    if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
      return;
    }
    // 画面の横幅のサイズ変動があった時のみ高さを再計算する
    vw = window.innerWidth;
    setFillHeight();
  });
  // 初期化
  setFillHeight();



  //スクロール後フッターにページトップ表示
  var showFlag = false;
  const topBtn = $('.c-pagetop');
  topBtn.css('bottom', '-150px');
  //スクロールが100に達したらボタン表示
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 100) {
      if (showFlag == false) {
        showFlag = true;
        topBtn.stop().animate({'bottom' : '20px'}, 200);
      }
    } else {
      if (showFlag) {
        showFlag = false;
        topBtn.stop().animate({'bottom' : '-150px'}, 200);
      }
    }
  });


  // メンバー アコーディオン
  const memberList = $('.js-member-list');
  if (memberList.length) {
    memberList.parent('.p-member-groupBody').addClass('js-memberAccordion');
  }
  memberList.on('click',function() {
    const contentHeight = $(this).parent('.p-member-groupBody').find('.p-member-list').height();/*それぞれの全体の高さを取得*/
    $(this).parent('.p-member-groupBody').css('height',contentHeight);/*クリックされたら大枠の高さを変更*/
    $(this).hide();
  });

  // ラボ 京都と東京の切り替え
  let tabs = $('.p-labo-tabItem');
  $('.p-labo-tabItem').on('click', function() {
    $('.is-active').removeClass('is-active');
    $(this).addClass('is-active');
    const index = tabs.index(this);
    $('.p-labo-section').removeClass('is-open').eq(index).addClass('is-open');
  });

  // ラボ #kandaの場合に初期表示を東京にする
  if ($('.p-labo-tabItem--kyoto').length) {
    let hash = location.hash || '#kyoto';
    if (hash === '#kanda') {
      $('.p-labo-tabItem--kyoto').removeClass('is-active');
      $('.p-labo-section--kyoto').removeClass('is-open');
      $('.p-labo-tabItem--tokyo').addClass('is-active');
      $('.p-labo-section--tokyo').addClass('is-open');
    }
  }

  // 本領域の研究集会（meeting）のiframeの高さを取得し、高さを可変にする
  $(window).on("load",function(){

    const meetingStyleBody = {
      fontFamily: "'Lato', 'Noto Sans JP', sans-serif",
      fontSize: "13px",
      color: "#2B2A25",
      margin: "0"
    }
    const meetingStyleTable = {
      borderSpacing: "0px",
      borderCollapse: "collapse",
    }
    const meetingStyleTable2 = {
      marginTop: "80px"
    }
    const meetingStyleCaption = {
      color: "#2B2A25",
      fontSize: "22px",
      fontWeight: "bold",
      textAlign: "left",
      marginBottom: "8px",
    }
    const meetingStyleTh = {
      color: "#2B2A25",
      fontSize: "13px",
      padding: "15px",
      backgroundColor: '#E5F3FA',
      border: "1px solid #FFFFFF"
    }
    const meetingStyleTd = {
      padding: "8px 6px",
      backgroundColor: '#ffffff',
      border: "1px solid #F2F2F2"
    }
    const meetingStyleArrowAsc = {
      borderBottomColor: '#2B2A25'
    }
    const meetingStyleArrowDsc = {
      borderTopColor: '#2B2A25'
    }
    const meetingStyleLink = {
      color: "#0145AA",
    }
    $('.js-meeting-iframe').contents().find('body').css(meetingStyleBody);
    $('.js-meeting-iframe').contents().find('table').css(meetingStyleTable);
    $('.js-meeting-iframe').contents().find('table:last-of-type').css(meetingStyleTable2);
    $('.js-meeting-iframe').contents().find('caption').css(meetingStyleCaption);
    $('.js-meeting-iframe').contents().find('table th').css(meetingStyleTh);
    $('.js-meeting-iframe').contents().find('table td').css(meetingStyleTd);
    $('.js-meeting-iframe').contents().find('.arrow.asc').css(meetingStyleArrowAsc);
    $('.js-meeting-iframe').contents().find('.arrow.dsc').css(meetingStyleArrowDsc);
    $('.js-meeting-iframe').contents().find('a').css(meetingStyleLink);

		$('.js-meeting-iframe').each(function(){
			const D = $(this).get(0).contentWindow.document;
			const innerHeight = Math.max(
				D.body.scrollHeight, D.documentElement.scrollHeight,
				D.body.offsetHeight, D.documentElement.offsetHeight,
				D.body.clientHeight, D.documentElement.clientHeight
			);
			$(this).removeAttr("height").css('height', innerHeight + 'px');
		});
    
	});


  // トップページからメンバーのアンカーリンクに飛んだ場合のposition:fixedとの調整
  const url = $(location).attr('href');
  if(url.indexOf("#") != -1){
    const anchor = url.split("#");
    const target = $('#' + anchor[anchor.length - 1]);
    if(target.length){
      const pos = Math.floor(target.offset().top) - 100;
      $("html, body").animate({scrollTop:pos}, 500);
    }
  }
  
});